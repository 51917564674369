<template>
    <b-container>
        <b-modal id="modal-session-app" ok-only ok-variant="warning" ok-title="Login" modal-class="modal-session-app"
            centered title="Expired !" @ok="gotologin()">
            <b-card-text>Session Expired</b-card-text>
        </b-modal>
        <validation-observer ref="searchForTheKeyword" #default="{ invalid }">
            <b-form id="searchForTheKeyword" @submit.prevent="filterKeywordsByName(invalid)">
                <b-card class="d-flex mb-0">
                    <b-row class="border rounded p-2 justify-content-center align-items-center mx-0">
                        <b-col cols="3">
                            <h3 class="mb-0">Keyword Research</h3>
                        </b-col>
                        <b-col cols="7">
                            <b-container>
                                <b-row>
                                    <b-col cols="12">
                                        <b-form-group class="mb-0">
                                            <input type="text" class="form-control" placeholder="Enter The Keyword."
                                                v-model="keywordName" />
                                            <small class="text-danger keyword-name-error"></small>
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                            </b-container>
                        </b-col>
                        <b-col cols="2">
                            <b-container>
                                <b-row>
                                    <b-col cols="12" class="d-flex justify-content-center">
                                        <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="outline-info"
                                            class="mr-1 circle" type="submit" v-b-tooltip.hover title="Search">
                                            <feather-icon icon="SearchIcon" size="14" />
                                        </b-button>
                                    </b-col>
                                </b-row>
                            </b-container>
                        </b-col>
                    </b-row>
                </b-card>
            </b-form>
        </validation-observer>

        <b-card ref="filterCard" class="my-1 hidden">
            <b-row class="border rounded mx-0 p-2 justify-content-center">
                <b-col cols="4">
                    <b-container>
                        <b-row>
                            <b-col cols="12" class="mb-1">
                                <h5 class="mb-0">Account :</h5>
                                <small class="text-muted"> Choose The Account Name. </small>
                            </b-col>
                            <b-col cols="12">
                                <b-form-group>
                                    <validation-provider #default="{ errors }" name="Account Name" rules="required">
                                        <v-select id="h-account-name" v-model="accountName"
                                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                            :state="errors.length > 0 ? false : null" :options="accountNames" />
                                        <small class="text-danger package-name-error"></small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                        </b-row>
                    </b-container>
                </b-col>
                <b-col cols="4">
                    <b-container>
                        <b-row>
                            <b-col cols="12" class="mb-1">
                                <h5 class="mb-0">Application :</h5>
                                <small class="text-muted"> Choose The Application Name. </small>
                            </b-col>
                            <b-col cols="12">
                                <b-form-group>
                                    <validation-provider #default="{ errors }" name="Package Name" rules="required">
                                        <v-select id="h-pacakge-name" v-model="packageName"
                                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                            :state="errors.length > 0 ? false : null" :options="packageNames" />
                                        <small class="text-danger package-name-error"></small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                        </b-row>
                    </b-container>
                </b-col>
                <b-col cols="4">
                    <b-container>
                        <b-row>
                            <b-col cols="12" class="mb-1">
                                <h5 class="mb-0">Period :</h5>
                                <small class="text-muted"> Choose The Import Period. </small>
                            </b-col>
                            <b-col cols="12">
                                <b-form-group>
                                    <validation-provider #default="{ errors }" name="Package Name" rules="required">
                                        <v-select id="h-pacakge-name" v-model="period"
                                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                            :state="errors.length > 0 ? false : null" :options="periods" />
                                        <small class="text-danger package-name-error"></small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                        </b-row>
                    </b-container>
                </b-col>
                <b-col cols="6">
                    <b-container>
                        <b-row>
                            <b-col cols="12" class="mb-1">
                                <h5 class="mb-0">Exclude :</h5>
                                <small class="text-muted"> Exclude Ex: All, Test... </small>
                            </b-col>
                            <b-col cols="12">
                                <b-form-group>
                                    <input type="text" class="form-control" placeholder="Ex: All, Test..."
                                        v-model="excludeText" />
                                </b-form-group>
                            </b-col>
                        </b-row>
                    </b-container>
                </b-col>
                <b-col cols="6">
                    <b-container>
                        <b-row>
                            <b-col cols="12" class="mb-1">
                                <h5 class="mb-0 invisible">:</h5>
                                <small class="text-muted">
                                    Exclude All search terms & Other.
                                </small>
                            </b-col>
                            <b-col cols="12">
                                <input type="checkbox" class="mb-1" style="width: 40px; height: 40px"
                                    v-model="exclude" />
                            </b-col>
                        </b-row>
                    </b-container>
                </b-col>
            </b-row>
        </b-card>

        <b-row class="d-flex justify-content-end my-1 mx-0">
            <div class="float-right">
                <div class="con-vs-tooltip">
                    <b-button
                        class="p-1 shadow-drop rounded-lg d-theme-dark-bg cursor-pointer sm:inline-flex text-dark feather-icon select-none relative"
                        v-b-tooltip.hover title="Filter" @click="toggleFilterCard()">
                        <feather-icon icon="FilterIcon" size="14" />
                    </b-button>
                </div>
            </div>
        </b-row>

        <b-card id="keywords-card" class="hidden">
            <b-row>
                <b-col md="6" class="mb-1" offset="6">
                    <b-form-group label="Filter" label-cols-sm="3" label-align-sm="right" label-size="sm"
                        label-for="filterInput" class="mb-0 d-flex align-items-center justify-content-end">
                        <b-input-group size="sm">
                            <b-form-input id="filterInput" v-model="filter" type="search"
                                placeholder="Type to Search" />
                            <b-input-group-append>
                                <b-button :disabled="!filter" @click="filter = ''">
                                    Clear
                                </b-button>
                            </b-input-group-append>
                        </b-input-group>
                    </b-form-group>
                </b-col>

                <b-col cols="12">
                    <b-table striped hover responsive :per-page="perPage" :current-page="currentPage" :items="items"
                        :fields="fields" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc"
                        :sort-direction="sortDirection" :filter="filter" :filter-included-fields="filterOn"
                        @filtered="onFiltered" class="keyword-research">
                        <template #cell(actions)="data">
                            <b-button-group>
                                <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="flat-danger"
                                    class="btn-icon rounded-circle" v-b-tooltip.hover title="Delete"
                                    @click="confirmDeleteKeyword(data.item.id)">
                                    <feather-icon icon="Trash2Icon" size="20" />
                                </b-button>
                            </b-button-group>
                        </template>
                        <template #cell(percentage)="data">
                            {{
                                data.item.percentage
                                    ? parseFloat(data.item.percentage).toFixed(2) + " %"
                            : ""
                            }}
                        </template>
                        <!--
            <template #cell(appPosition)="data">
              {{ data.item.appPosition < 0 ? "-" : data.item.appPosition }}
            </template>
            -->
                    </b-table>
                </b-col>
                <b-col cols="6">
                    <b-form-group class="mb-0">
                        <label class="d-inline-block text-sm-left mr-50">Per page</label>
                        <b-form-select id="perPageSelect" v-model="perPage" size="sm" :options="pageOptions"
                            class="w-25" />
                    </b-form-group>
                </b-col>
                <b-col cols="6">
                    <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="center"
                        size="sm" class="my-0" />
                </b-col>
            </b-row>
        </b-card>
    </b-container>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import vSelect from "vue-select";
import {
    BRow,
    BCol,
    BModal,
    BCard,
    BFormSelect,
    BTable,
    BInputGroup,
    BFormTextarea,
    BFormInput,
    BFormGroup,
    BFormFile,
    BImg,
    BForm,
    BInputGroupAppend,
    BCardText,
    BButtonGroup,
    VBTooltip,
    BPagination,
    BButton,
    VBHover,
    BContainer,
    BFormInvalidFeedback,
    BAspect,
} from "bootstrap-vue";
import { removeLocalStorageItems } from "@/helper/helpers-funcs.js";
import axios from "@axios";
import appsAxios from "@/libs/appsAxios";
import router from "@/router";
import Ripple from "vue-ripple-directive";
import Editor from "@tinymce/tinymce-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
export default {
    components: {
        ValidationProvider,
        ValidationObserver,
        BRow,
        BCol,
        BFormTextarea,
        BCard,
        BContainer,
        BFormInput,
        BFormGroup,
        BFormFile,
        BImg,
        BFormSelect,
        BInputGroupAppend,
        BForm,
        BTable,
        BCardText,
        BInputGroup,
        BModal,
        BButtonGroup,
        VBTooltip,
        BPagination,
        BButton,
        vSelect,
        BAspect,
        editor: Editor,
        BFormInvalidFeedback,
    },
    directives: {
        "b-tooltip": VBTooltip,
        "b-hover": VBHover,
        Ripple,
    },
    data() {
        return {
            keyword: null,
            _id: null,
            packageName: null,
            packageNames: [],
            periods: [],
            period: null,
            accountName: null,
            accountNames: [],
            keywordNames: [],
            keywordName: null,
            Keywords: [],
            keywordsFile: null,
            perPage: 25,
            pageOptions: [10, 25, 100],
            totalRows: 1,
            currentPage: 1,
            sortBy: "desc",
            sortDesc: false,
            sortDirection: "asc",
            filter: null,
            items: [],
            filterOn: [],
            fields: [
                {
                    key: "name",
                    label: "Keyword",
                },
                {
                    key: "listing_visitors",
                    label: "listing visitors",
                    sortable: true,
                },
                {
                    key: "listing_acquisitions",
                    label: "Acquisitions",
                    sortable: true,
                },
                {
                    key: "percentage",
                    label: "%",
                    sortable: true,
                },
                {
                    key: "title",
                    label: "Application",
                },
                // {
                //   key: "appPosition",
                //   label: "Position",
                // },
                {
                    key: "account_name",
                    label: "Account",
                },

                // "Actions",
            ],
            exclude: null,
            excludeText: null,
            token: null,
            server: 1,
        };
    },
    mounted() {
        this.getPeriods();
        this.showAllAccounts();
        this.token = JSON.parse(localStorage.getItem("userAccess")).token;
        this.server = JSON.parse(localStorage.getItem("userAccess")).server;
    },
    created() { },
    watch: {
        accountName(value) {
            value ? this.showAppsByAccount(value.value) : this.showAppsByAccount(0);
        },
    },
    methods: {
        async getPeriods() {
            await axios
                .get(`/get-imports-periods`)
                .then(({ data }) => {
                    data.periods.forEach((p) => {
                        this.periods.push(p.start + " to " + p.end);
                    });
                })
                .catch(console.error);
        },
        getAppPositionByKeyword(keywords) {
            return keywords.map(async (keyword) => {
                let appPosition = -1;
                const response = await appsAxios.get(`https://sc1.clicklab.app/api/apps/?q=${keyword.name}`);
                const { status } = response.data;
                if (status === "Unauthorized") {
                    removeLocalStorageItems();
                    this.$root.$emit("bv::show::modal", "modal-session-app");
                    return;
                }
                const data = response.data.results;
                data.forEach((result, index) => {
                    if (result.title === keyword.title) {
                        appPosition = ++index;
                        return { ...keyword, appPosition };
                    }
                });

                return { ...keyword, appPosition: -1 };
            });
        },
        toggleFilterCard() {
            this.$refs.filterCard.classList.toggle("hidden");
        },
        async filterKeywordsByName() {
            document.getElementById("keywords-card").classList.remove("hidden");
            if (this.keywordName || this.packageName) {
                var keyword_name = this.keywordName ? this.keywordName : "";
                var application_id = this.packageName ? this.packageName.value : 0;
                var account_id = this.accountName ? this.accountName.value : 0;
                var excludeText = this.excludeText ? this.excludeText : "";
                var startP = this.period ? this.period.split("to")[0] : "";
                var endP = this.period ? this.period.split("to")[1] : "";
                var exclude = this.exclude ? "on" : "off";
                await axios.post("/get-keywords-by-name", {
                        keyword_name,
                        application: application_id,
                        account: account_id,
                        excludeText,
                        exclude,
                        endP,
                        startP,
                    })
                    .then((response) => {
                        if (response.data.status === "Unauthorized") {
                            removeLocalStorageItems();
                            router.push({ name: "auth-login" });
                        }
                        if (response.data.status === "success") {
                            const excludedNames = [
                                "All search terms",
                                "No search terms specified",
                                "Other",
                                "Aucun terme de recherche spécifié"
                            ];
                            if (!this.accountName?.label) {
                                this.items = response.data.keywords;
                            } else {
                                if (this.period) {
                                    const [start_period, end_period] = this.period.split(" to ");
                                    this.items = response.data.keywords.filter(item => {
                                        return item.account_name === this.accountName.label &&
                                            item.start_period === start_period && item.end_period === end_period;
                                    });
                                } else {
                                    this.items = response.data.keywords.filter(item => item.account_name === this.accountName.label);
                                }
                            }
                            this.totalRows = this.items.length;
                            if (exclude === "on") {
                                this.items = this.items.filter(item => !excludedNames.includes(item.name));
                            }
                        }
                    })
                    .catch(console.error);
            } else {
                this.getAllKeywords();
            }
        },
        async showAppsByAccount(id) {
            await axios
                .post(`/get-apps-by-account`, {
                    accountId: id,
                    user_token: JSON.parse(localStorage.getItem("accessToken")),
                })
                .then(({ data }) => {
                    if (data.status == "Unauthorized") {
                        removeLocalStorageItems();
                        router.push({ name: "auth-login" });
                    }
                    this.packageNames = [];
                    if (data.count > 0) {
                        data.apps.forEach((app) => {
                            this.packageNames.push({
                                label: app.title,
                                value: app.id,
                            });
                        });
                    }
                })
                .catch(console.error);
        },
        async getAllKeywords() {
            var exclude = this.exclude ? "on" : "off";
            await axios
                .get(`/get-keywords?exclude=${exclude}`)
                .then((response) => {
                    if (response.data.status == "Unauthorized") {
                        removeLocalStorageItems();
                        router.push({ name: "auth-login" });
                    }
                    this.keywordNames = [];
                    if (response.data.status === "success") {
                        const excludedNames = [
                            "All search terms",
                            "No search terms specified",
                            "Other",
                            "Aucun terme de recherche spécifié"
                        ];
                        if (!this.accountName?.label) {
                            this.items = response.data.keywords;
                        } else {
                            if (this.period) {
                                const [start_period, end_period] = this.period.split(" to ");
                                this.items = response.data.keywords.filter(item => {
                                    return item.account_name === this.accountName.label &&
                                        item.start_period === start_period && item.end_period === end_period;
                                });
                            } else {
                                this.items = response.data.keywords.filter(item => item.account_name === this.accountName.label);
                            }
                        }
                        if (exclude === "on") {
                            this.items = this.items.filter(item => !excludedNames.includes(item.name));
                        }
                        this.totalRows = this.items.length;
                        response.data.keywords.forEach((keyword) => {
                            this.keywordNames.push({
                                label: keyword.name,
                                value: keyword.id,
                            });
                        });
                    }
                })
                .catch(console.error);
        },
        async searchForTheKeyword(i) {
            if (!i) {
                await axios
                    .post("/search-keyword", {})
                    .then(({ data }) => {
                        if (data.status == "Unauthorized") {
                            removeLocalStorageItems();
                            router.push({ name: "auth-login" });
                        }
                        if (data.status == "success") {
                            this.showToast("success", "SaveIcon", "Done", data.message);
                        } else if (data.status == "failed") {
                            this.showToast(
                                "danger",
                                "AlertTriangleIcon",
                                "Error",
                                "Something went wrong!"
                            );
                        }
                    })
                    .catch(console.error);
            }
        },
        showToast(variant, icon, title, text) {
            this.$toast({
                component: ToastificationContent,
                props: {
                    title,
                    icon,
                    text,
                    variant,
                },
            });
        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        },

        async showAllAccounts() {
            await axios
                .post("/get-accounts", {
                    user_token: JSON.parse(localStorage.getItem("accessToken")),
                })
                .then(({ data }) => {
                    if (data.status == "Unauthorized") {
                        removeLocalStorageItems();
                        router.push({ name: "auth-login" });
                    }

                    this.accountNames = [];
                    data.accounts.forEach((account) => {
                        this.accountNames.push({
                            label: account.account_name,
                            value: account.id,
                        });
                    });
                })
                .catch(console.error);
        },
        gotologin() {
            this.$router.push({ name: "auth-login" });
        },
    },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/dashboard-ecommerce.scss";
@import "@core/scss/vue/pages/dashboard-ecommerce.scss";
@import "@core/scss/vue/libs/vue-select.scss";
</style>
